<template>
  <v-card class="page-container">
    <v-row>
      <v-col cols="12">
        <div class="member-balance">
          <app-member-balance :parent="this.routeName.MOBILE_ACCOUNT"></app-member-balance>
        </div>
      </v-col>
    </v-row>
    <!--    <v-list>-->
    <v-list-item-group v-model="model" multiple>
      <!--parent nav items        -->
      <v-list-item v-for="(item, i) in navItems" :key="i">
        <v-list-group class="parentNav-list-group" :class="navItems.length > i + 1 ? '' : 'no-border'">
          <v-icon width="24" height="24" slot="prependIcon" color="primary" class="parentNav-icon">{{ `$${item.icon}` }}</v-icon>
          <template v-slot:activator>
            <v-list-item-content class="parentNav-item-content">
              <v-list-item-title class="parentNav-title">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- children nav items          -->
          <div v-for="child in item.items" :key="child.title">
            <!--external routes/links-->
            <v-list-item v-if="child.isExtLink" class="child-list-item" :href="child.route" target="_blank">
              <v-list-group class="childNav-list-group" no-action :append-icon="`mdi-chevron-right`">
                <!--                   <v-list-item-title v-text="child.title"></v-list-item-title> -->
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="childNav-title">{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-group>
            </v-list-item>
            <!--application routes-->
            <v-list-item v-else class="child-list-item" :to="{ name: child.route }">
              <v-list-group class="childNav-list-group" no-action :append-icon="`mdi-chevron-right`">
                <!--                   <v-list-item-title v-text="child.title"></v-list-item-title> -->
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="childNav-title">{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-group>
            </v-list-item>
          </div>
        </v-list-group>
      </v-list-item>
    </v-list-item-group>

    <!--      <v-list-item-group  v-model="item.active" :prepend-icon="item.action" no-action >-->

    <!--        &lt;!&ndash;expansion list&ndash;&gt;-->
    <!--        <v-list-item v-for="child in item.items" :key="child.title" :to="{ name: child.route }">-->
    <!--          <v-list-item-content>-->
    <!--            <v-list-item-title>{{ child.title }}</v-list-item-title>-->
    <!--          </v-list-item-content>-->
    <!--        </v-list-item>-->
    <!--      </v-list-item-group>-->

    <!--      <v-list-group v-for="(item, index) in specialNavItems" :key="item.title" v-model="item.active" :prepend-icon="item.icon">-->
    <!--        <template v-slot:activator>-->
    <!--          <v-list-item-title v-text="item.title"></v-list-item-title>-->
    <!--        </template>-->
    <!--        <v-list-item v-for="child in item.items" :key="child.title" :to="{name:child.route}">-->
    <!--          <v-list-item-title>{{ child.title }}</v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--      </v-list-group>-->
    <!--    </v-list>-->
  </v-card>
</template>

<script>
import { uiHelper, locale } from '@/util'
import AppMemberBalance from '@/components/member/MemberBalance.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  name: 'mobileAccount',
  components: {
    AppMemberBalance
  },
  data: () => ({
    routeName: ROUTE_NAME,
    specialNavItems: [
      {
        icon: 'mdi-calendar-star',
        title: 'Special 2',
        items: [
          {
            title: 'Refer & Earn',
            route: ROUTE_NAME.REFERRAL_TIER
          }
        ]
      }
    ],
    model: [0, 3],
    navItems: [
      {
        title: locale.getMessage('label.history'),
        active: true,
        icon: 'customHistory',
        items: [
          {
            title: locale.getMessage('mobileMenuTitle.betHistory'),
            route: ROUTE_NAME.MOBILE_BETTING_HISTORY
          },
          {
            title: locale.getMessage('label.turnoverHistory'),
            route: ROUTE_NAME.MOBILE_TO_HISTORY
          },
          {
            title: locale.getMessage('mobileMenuTitle.walletHistory'),
            route: ROUTE_NAME.MOBILE_TRANSACTION_HISTORY
          }
          // {
          //   title: 'Promotion History',
          //   route: ROUTE_NAME.MOBILE_PROMO_HISTORY
          // }
          // {
          //   title: 'Transactions',
          //   route: ROUTE_NAME.MOBILE_ACCOUNT
          // }
          // {
          //   title:'',
          //   route:ROUTE_NAME
          // }
        ]
      },
      {
        icon: 'customSpecial',
        title: locale.getMessage('mobileMenuTitle.special'),
        items: [
          {
            title: locale.getMessage('mobileMenuTitle.referral'),
            route: ROUTE_NAME.REFERRAL_TIER
          }
        ]
      },
      {
        icon: 'customRewards',
        title: locale.getMessage('label.bankDetail'),
        items: [
          {
            title: locale.getMessage('label.bankDetail'),
            route: ROUTE_NAME.BANK_ACCOUNT
          }
        ]
      },
      {
        icon: 'customSetting',
        title: locale.getMessage('mobileMenuTitle.setting'),
        items: [
          {
            title: locale.getMessage('label.profile'),
            route: ROUTE_NAME.PERSONAL
          },
          { title: locale.getMessage('label.changePassword'), route: ROUTE_NAME.CHANGE_PASSWORD }
        ]
      }
    ]
  })
}
</script>

<style lang="scss">
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before,
.theme--light.v-list-item:hover::before {
  opacity: 0 !important;
}

.page-container {
  height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-list-item:hover::before {
  opacity: 0 !important;
}

.parentNav-list-group {
  width: 100%;
  border-bottom: 2px solid #dadada;

  .v-list-group__header {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &.v-list-group--active {
    border-bottom: unset;
  }
  //parentNav expanded or active
  .v-list-group__header .v-item--active {
    border-bottom: 2px solid #dadada;
  }
  .parentNav-icon {
  }
  .v-list-group__header__prepend-icon {
    margin-right: 15px !important;
    margin-top: auto;
    margin-bottom: auto;
  }

  //parentNav group appendicon
  //.v-list-group--active>.v-list-group__header>.v-list-group__header__append-icon .v-icon{
  //  transform();
  //}
}
.no-border {
  border-bottom: unset;
}
.parentNav-title {
  font-size: 0.9rem !important;
}
.child-list-item {
  padding: 0 0 0 10px;
}
.childNav-list-group {
  width: 100%;
  .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: unset !important;
  }

  .childNav-title {
    color: #9b9b9b !important;
    font-size: 0.88rem !important;
  }

  .v-item--active {
    border-bottom: unset !important;
  }
}

.mdi-chevron-right {
  transform: unset !important;
}

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 25px;
}
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 25px;
}
.member-balance {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 35px;
  margin-bottom: 20px;
}
</style>
